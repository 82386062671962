<template>
    <section id="partner_area" class="section_padding_top">
        <div class="container">
            <div class="partner_area_bg">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="partner_area_left">
                            <h5>Find your best travel partner</h5>
                            <h2>Prepare yourself and let’s enjoy
                                the beauty of the world</h2>
                            <p>Sint est eu sit ipsum enim amet esse sunt incididunt. Occaecat aliquip commodo ipsum
                                officia in Lorem commodo aliquip dolore. Nisi domip excepteur commodo ea nostrud mollit.
                            </p>
                            <div class="partner_video_area">

                                <a class="video_btn video_play_area" title="Youtube Video" @click="modalToggle"
                                    data-bs-toggle="modal" data-bs-target="#myModal" href="#!"><i
                                        class="fas fa-play"></i></a>

                                <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }"
                                    tabindex="-1" role="dialog">
                                    <div class="modal-dialog modal-dialog-centered">
                                        <div class="modal-content">
                                            <div class="modal-body logout_modal_content">
                                                <div class="btn_modal_closed">
                                                    <button type="button" data-bs-dismiss="modal" @click="modalToggle"
                                                        aria-label="Close"><i class="fas fa-times"></i></button>
                                                </div>

                                                <div id="yt-player">
                                                    <iframe width="100%" height="315"
                                                        src="https://www.youtube.com/embed/rrT6v5sOwJg" frameborder="0"
                                                        allowfullscreen></iframe>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="local_counter_area">
                                <div class="local_counter_box">
                                    <h2>12K</h2>
                                    <h5>Local guides</h5>
                                </div>
                                <div class="local_counter_box">
                                    <h2>750+</h2>
                                    <h5>Destinations</h5>
                                </div>
                                <div class="local_counter_box">
                                    <h2>100%</h2>
                                    <h5>Happiness</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

export default {
    name: "FindTravellPartner",
    data() {
        return {
            active: false,
        }
    },

    methods: {
        modalToggle() {
            const body = document.querySelector("body")
            this.active = !this.active
            this.active ? body.classList.add("modal-open") : body.classList.remove("modal-open")

            var videos = document.querySelectorAll('iframe, video');
            Array.prototype.forEach.call(videos, function (video) {
                if (video.tagName.toLowerCase() === 'video') {
                    video.pause();
                } else {
                    var src = video.src;
                    video.src = src;
                }
            });

        },
    },
};
</script>