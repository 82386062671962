<template>
  <div class="col-lg-4">
    <div class="dashboard_sidebar">
      <div class="dashboard_sidebar_user">
        <img src="../../assets/img/common/dashboard-user.png" alt="img" />
        <h3>Sherlyn Chopra</h3>
        <p><a href="tel:+00-123-456-789">+00 123 456 789</a></p>
        <p><a href="mailto:sherlyn@domain.com">sherlyn@domain.com</a></p>
      </div>
      <div class="dashboard_menu_area">
        <ul>
          <li>
            <router-link to="/dashboard"
              ><i class="fas fa-tachometer-alt"></i>Dashboard</router-link
            >
          </li>
          <MyBookingOption />
          <li>
            <router-link to="/my-profile" class="active"
              ><i class="fas fa-user-circle"></i>My profile</router-link
            >
          </li>
          <li>
            <router-link to="/dashboard"
              ><i class="fas fa-wallet"></i>Wallet</router-link
            >
          </li>
          <li>
            <router-link to="/notification"
              ><i class="fas fa-bell"></i>Notifications</router-link
            >
          </li>
          <LogoutBtn />
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import LogoutBtn from "@/components/dashboard/LogoutBtn.vue";
import MyBookingOption from "@/components/dashboard/MyBookingOption.vue";
export default {
  name: "AdminSidebar",
  components: {
    LogoutBtn,
    MyBookingOption,
  },
};
</script>