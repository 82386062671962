<template>
  <section id="tour_details_main" class="section_padding">
    <!-- Modal -->
<div class="modal fade" id="modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Entrar em contato</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div
                  class="tour_package_details_bar_list first_child_padding_none"
                >
                  <form @submit.prevent>
                    <div class="mb-3">
                      <label for="mensagem" class="form-label">Mensagem</label>
                      <input
                        type="textarea"
                        class="form-control"
                        id="mensagem"
                        required
                        v-model="form.mensagem"
                        aria-describedby="emailHelp"
                      />
                      <div id="emailHelp" class="form-text">
                        Mensagem será enviada pelo whatsapp
                      </div>
                    </div>
                    <div class="mb-3">
                      <label for="nome" class="form-label">Nome</label>
                      <input
                        type="text"
                        class="form-control"
                        required
                        v-model="form.nome"
                        id="nome"
                        :focus="true"
                      />
                    </div>
                    <div class="mb-3">
                      <label for="nome" class="form-label">E-mail</label>
                      <input
                        type="text"
                        class="form-control"
                        required
                        v-model="form.email"
                        id="nome"
                      />
                    </div>
                    <button
                      type="submit"
                      :disabled="!form.email || !form.nome || !form.mensagem"
                      class="btn btn-success"
                      style="width: 100%"
                      @click="submitWhatts()"
                    >
                      Enviar
                    </button>
                  </form>
                </div>
      </div>
    </div>
  </div>
</div>
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="tour_details_leftside_wrapper">
            <div class="tour_details_heading_wrapper">
              <div class="tour_details_top_heading">
                <h2>Hotel castle salam</h2>
                <h5><i class="fas fa-map-marker-alt"></i> Salvador - BA</h5>
              </div>
            </div>
            <!-- <div class="tour_details_top_bottom">
                            <div class="toru_details_top_bottom_item">
                                <div class="tour_details_top_bottom_icon">
                                    <img src="../../assets/img/icon/ac.png" alt="icon">
                                </div>
                                <div class="tour_details_top_bottom_text">
                                    <p>Air condition</p>
                                </div>
                            </div>
                            <div class="toru_details_top_bottom_item">
                                <div class="tour_details_top_bottom_icon">
                                    <img src="../../assets/img/icon/tv.png" alt="icon">
                                </div>
                                <div class="tour_details_top_bottom_text">
                                    <p>Flat television</p>
                                </div>
                            </div>
                            <div class="toru_details_top_bottom_item">
                                <div class="tour_details_top_bottom_icon">
                                    <img src="../../assets/img/icon/gym.png" alt="icon">
                                </div>
                                <div class="tour_details_top_bottom_text">
                                    <p>Fitness center</p>
                                </div>
                            </div>
                            <div class="toru_details_top_bottom_item">
                                <div class="tour_details_top_bottom_icon">
                                    <img src="../../assets/img/icon/wifi.png" alt="icon">
                                </div>
                                <div class="tour_details_top_bottom_text">
                                    <p>Free Wi-fi</p>
                                </div>
                            </div>
                        </div> -->
            <div class="tour_details_img_wrapper">
              <div class="image__gallery">
                <div class="gallery">
                  <div class="gallery__slideshow">
                    <div class="gallery__slides">
                      <div class="gallery__slide">
                        <img
                          class="gallery__img"
                          :src="slide.img"
                          alt=""
                          v-for="(slide, index) in gallerySlides"
                          :key="index"
                          :style="
                            slideIndex === index
                              ? 'display:block;'
                              : 'display:none;'
                          "
                        />
                      </div>
                    </div>
                    <a @click.prevent="move(-1)" class="gallery__prev"
                      >&#10095;</a
                    >
                    <a @click.prevent="move(1)" class="gallery__next"
                      >&#10094;</a
                    >
                  </div>

                  <div class="gallery__content">
                    <div class="gallery__items">
                      <div
                        class="gallery__item"
                        :class="{
                          'gallery__item--is-acitve': slideIndex === index,
                        }"
                        v-for="(slide, index) in gallerySlides"
                        :key="`item-${index}`"
                      >
                        <img
                          :src="slide.img"
                          @click="currentSlide(index)"
                          class="gallery__item-img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tour_details_boxed">
              <h3 class="heading_theme">Visão geral</h3>
              <div class="tour_details_boxed_inner">
                <p>
                  Situado em Rio dos Cedros, Santa Catarina, a 44 km da Estação
                  Rodoviária de Blumenau, o Ada Verde oferece acomodações com
                  Wi-Fi gratuito, churrasqueira, jardim e estacionamento
                  privativo gratuito. Algumas acomodações incluem terraço com
                  vista da montanha, cozinha compacta totalmente equipada e
                  banheiro privativo com chuveiro. O chalé fica a 45 km da Vila
                  Germânica e do Museu da Água. O aeroporto mais próximo é o
                  Aeroporto de Joinville - Lauro Carneiro de Loyola, a 84 km do
                  Ada Verde. Casais particularmente gostam da localização — eles
                  deram nota 9,5 para viagem a dois.
                </p>
                <h4>
                  <strong> Principais comodidades </strong>
                </h4>
                <p class="d-flex flex-wrap justify-content-between gap-3 mt-3">
                  <span>
                    <i class="fas fa-users" style="margin-right: 0.3rem"></i>
                    Quartos para famílias
                  </span>
                  <span>
                    <i class="fas fa-tree" style="margin-right: 0.3rem"></i>
                    Jardim
                  </span>
                  <span>
                    <i class="fas fa-car" style="margin-right: 0.3rem"></i>
                    Estacionamento grátis
                  </span>
                  <span>
                    <i class="fas fa-wifi" style="margin-right: 0.3rem"></i>
                    Wi-Fi gratuito
                  </span>
                  <span>
                    <i class="fas fa-dumbbell" style="margin-right: 0.3rem"></i>
                    Academia
                  </span>
                  <span>
                    <i class="fas fa-smoking" style="margin-right: 0.3rem"></i>
                    Área específica para fumantes
                  </span>
                </p>
              </div>
            </div>
            <div style="margin-top: 2rem">
                <button class="btn btn-success" style="width: 100%" data-bs-toggle="modal" data-bs-target="#modal" type="button">FALE COM HOTEL AGORA MESMO!!!</button>
            </div>
            <div class="tour_details_boxed">
              <h3 class="heading_theme">Apartamentos</h3>
              <div class="tour_details_boxed_inner">
                <table>
                  <thead>
                    <tr>
                      <th style="width: 70%">Tipo de apartamento</th>
                      <th style="text-align: center">Acomoda</th>
                      <th style="text-align: center">Preço diaria</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div>
                          <h5 class="text-primary">
                            <strong>Apartamento Superior</strong>
                          </h5>
                          <div class="mt-2 text-secondary">
                            <div>
                              <span class="d-block">
                                <strong>Quarto 2:</strong> 1 cama de casal 1
                                cama de solteiro
                              </span>
                              <span class="d-block">
                                <strong>Sala de estar:</strong> 1 sofa cama
                              </span>
                            </div>
                          </div>
                          <div>
                            <p class="d-flex flex-wrap gap-3 mt-3">
                              <span>
                                <i
                                  class="fas fa-users"
                                  style="margin-right: 0.3rem"
                                ></i>
                                Quartos para famílias
                              </span>
                              <span>
                                <i
                                  class="fas fa-tree"
                                  style="margin-right: 0.3rem"
                                ></i>
                                Jardim
                              </span>
                              <span>
                                <i
                                  class="fas fa-car"
                                  style="margin-right: 0.3rem"
                                ></i>
                                Estacionamento grátis
                              </span>
                              <span>
                                <i
                                  class="fas fa-wifi"
                                  style="margin-right: 0.3rem"
                                ></i>
                                Wi-Fi gratuito
                              </span>
                              <span>
                                <i
                                  class="fas fa-dumbbell"
                                  style="margin-right: 0.3rem"
                                ></i>
                                Academia
                              </span>
                              <span>
                                <i
                                  class="fas fa-smoking"
                                  style="margin-right: 0.3rem"
                                ></i>
                                Área específica para fumantes
                              </span>
                            </p>
                          </div>
                        </div>
                      </td>
                      <td style="text-align: center">2</td>
                      <td style="text-align: center">R$ 2.000,00</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="tour_details_boxed">
              <h3 class="heading_theme">Regras da casa</h3>
              <div class="tour_details_boxed_inner regra">
                <ul class="d-flex">
                  <li class="first">
                    <i class="fas fa-sign-in-alt"></i>Entrada
                  </li>
                  <li class="flex-wrap">Das 12h00 ás 22h00</li>
                </ul>
                <ul class="d-flex">
                  <li><i class="fas fa-sign-out-alt"></i>Saída</li>
                  <li class="flex-wrap">Até 11h00</li>
                </ul>
                <ul class="d-flex">
                  <li class="first">
                    <i class="fas fa-strikethrough"></i>
                    Cancelamento/pré-pagamento
                  </li>
                  <li>
                    As políticas de cancelamento e pré-pagamento variam de
                    acordo com o tipo de acomodação. Adicione as datas da sua
                    estadia e visualize a política do quarto requerido.
                  </li>
                </ul>
                <ul class="d-flex">
                  <li class="first">
                    <i class="fas fa-child"></i> Crianças e camas
                  </li>
                  <li class="flex-wrap">
                    <span
                      style="margin-bottom: 0.5rem; display: block; width: 100%"
                    >
                      <strong> Políticas para crianças </strong>
                    </span>
                    Crianças de qualquer idade são bem-vindas. Para ver os
                    preços e as informações de ocupação certos, informe quantas
                    crianças fazem parte do seu grupo e a idade delas.

                    <span
                      style="margin: 0.5rem 0px; display: block; width: 100%"
                    >
                      <strong> Políticas para berços e camas extras </strong>
                    </span>
                    Não há capacidade para berços nesta acomodação. Não há
                    capacidade para camas extras nesta acomodação.
                  </li>
                </ul>
                <ul class="d-flex">
                  <li class="first">
                    <i class="fas fa-male"></i> Sem restrições de idade
                  </li>
                  <li class="flex-wrap">
                    Não há exigência de idade para o check-in
                  </li>
                </ul>
                <ul class="d-flex">
                  <li class="first">
                    <i class="fas fa-paw"></i> Animais de estimação
                  </li>
                  <li class="flex-wrap">Pets: não permitidos.</li>
                </ul>
                <ul class="d-flex">
                  <li class="first">
                    <i class="far fa-credit-card"></i> Pagamentos via
                    Booking.com
                  </li>
                  <li class="flex-wrap">
                    A Booking.com coletará o pagamento desta hospedagem em nome
                    da acomodação, porém, certifique-se de levar dinheiro para
                    cobrir quaisquer extras quando chegar lá.
                  </li>
                </ul>
              </div>
            </div>
            <div style="margin-top: 2rem">
                <button class="btn btn-success" style="width: 100%" data-bs-toggle="modal" data-bs-target="#modal" type="button">FALE COM HOTEL AGORA MESMO!!!</button>
            </div>
            <div class="tour_details_boxed">
              <h3 class="heading_theme">Endereço</h3>
              <div class="map_area">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3677.6962663570607!2d89.56355961427838!3d22.813715829827952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ff901efac79b59%3A0x5be01a1bc0dc7eba!2sAnd+IT!5e0!3m2!1sen!2sbd!4v1557901943656!5m2!1sen!2sbd"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-contact">
          <div class="tour_details_right_sidebar_wrapper">
            <div class="tour_detail_right_sidebar">
              <div class="tour_details_right_boxed">
                <div class="tour_details_right_box_heading">
                  <h3>Preço</h3>
                </div>
                <div class="tour_package_bar_price">
                  <h6><del>R$ 300,00</del></h6>
                  <h3>R$ 220,00 <sub>/Diaria</sub></h3>
                </div>

                <div class="tour_package_details_bar_list">
                  <h5>Instalações do hotel</h5>
                  <ul>
                    <li>
                      <i class="fas fa-circle"></i>Café da manhã buffet conforme
                      Itinerário
                    </li>
                    <li>
                      <i class="fas fa-circle"></i>Safari de camelo gratuito,
                      fogueira,
                    </li>
                    <li><i class="fas fa-circle"></i>Wi-fi grátis</li>
                    <li>
                      <i class="fas fa-circle"></i>Veículo confortável e
                      higiênico
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="tour_detail_right_sidebar">
              <div class="tour_details_right_boxed">
                <div class="tour_details_right_box_heading">
                  <h3>Contato</h3>
                </div>

                <div
                  class="tour_package_details_bar_list first_child_padding_none"
                >
                  <form @submit.prevent>
                    <div class="mb-3">
                      <label for="mensagem" class="form-label">Mensagem</label>
                      <input
                        type="textarea"
                        class="form-control"
                        id="mensagem"
                        required
                        v-model="form.mensagem"
                        aria-describedby="emailHelp"
                      />
                      <div id="emailHelp" class="form-text">
                        Mensagem será enviada pelo whatsapp
                      </div>
                    </div>
                    <div class="mb-3">
                      <label for="nome" class="form-label">Nome</label>
                      <input
                        type="text"
                        class="form-control"
                        required
                        v-model="form.nome"
                        id="nome"
                        :focus="true"
                      />
                    </div>
                    <div class="mb-3">
                      <label for="nome" class="form-label">E-mail</label>
                      <input
                        type="text"
                        class="form-control"
                        required
                        v-model="form.email"
                        id="nome"
                      />
                    </div>
                    <button
                      type="submit"
                      :disabled="!form.email || !form.nome || !form.mensagem"
                      class="btn btn-success"
                      style="width: 100%"
                      @click="submitWhatts()"
                    >
                      Enviar
                    </button>
                  </form>

                  <ul style="margin-top: 1.5rem">
                    <li>
                      <i class="fa fa-envelope" style="font-size: 1rem"></i
                      >contato@hotel.com.br
                    </li>
                    <li>
                      <i class="fa fa-phone" style="font-size: 1rem"></i> 71
                      99999-999
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>

export default {
  name: "HotelSearch",

  data() {
    return {
      gallerySlides: [
        { img: require("@/assets/img/hotel/hotel-big-1.png") },
        { img: require("@/assets/img/hotel/hotel-big-1.png") },
        { img: require("@/assets/img/hotel/hotel-big-1.png") },
        { img: require("@/assets/img/hotel/hotel-big-1.png") },
        { img: require("@/assets/img/hotel/hotel-big-1.png") },
        { img: require("@/assets/img/hotel/hotel-big-1.png") },
        { img: require("@/assets/img/hotel/hotel-big-1.png") },
      ],

      slideIndex: 0,

      slides: [
        { img: require("@/assets/img/hotel/small-1.png") },
        { img: require("@/assets/img/hotel/small-2.png") },
        { img: require("@/assets/img/hotel/small-3.png") },
        { img: require("@/assets/img/hotel/small-4.png") },
        { img: require("@/assets/img/hotel/small-5.png") },
        { img: require("@/assets/img/hotel/small-6.png") },
      ],

      form: {
        nome: "",
        mensagem:
          "gostaria de mais informações, vi este anúncio no site viagemok",
        email: "",
        telefone: "",
      },
    };
  },

  methods: {
    move(n) {
      if (this.gallerySlides.length <= this.slideIndex + n) {
        this.slideIndex = 0;
      } else if (this.slideIndex + n < 0) {
        this.slideIndex = this.gallerySlides.length - 1;
      } else {
        this.slideIndex += n;
      }
    },
    currentSlide(index) {
      this.slideIndex = index;
    },

    submitWhatts() {
      const form = this.form;
      const msg = `${form.nome ? "Sou " + form.nome : ""}, ${form.mensagem} ${
        form.email ? "meu e-mail: " + form.email : ""
      }`;

      const url = `https://api.whatsapp.com/send?phone=+557592108221&text=${msg}`;

      window.open(url, "_blank");

      this.form.email = "";
      this.form.nome = "";
    },
  },
};
</script>

<style scoped>
@media all and (min-width: 900px) {
  .col-contact {
    margin-top: 7rem;
  }
}

.regra li {
  width: 100%;
}

.regra ul {
  flex-basis: 10rem;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1rem 0px;
}
.regra i {
  font-size: 1.3rem;
  margin-right: 0.5rem;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
</style>
