<template>

    <!--  Common Author Area -->
    <CommonAuthorFour />

</template>
<script>
import LoginBanner from '@/components/user/LoginBanner.vue'
import CommonAuthorFour from '@/components/user/CommonAuthorFour.vue'
import Cta from '@/components/home/Cta.vue'


export default {
    name: "LoginView",
    components: {
        LoginBanner, CommonAuthorFour, Cta
    }
};
</script>