import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

export const firebaseApp = initializeApp({
    apiKey: "AIzaSyBOxe4Q3sA9r2T1oYS986XMKtFB4R6EH4Y",
    authDomain: "viagemok-b2cbf.firebaseapp.com",
    projectId: "viagemok-b2cbf",
    storageBucket: "viagemok-b2cbf.appspot.com",
    messagingSenderId: "343569448122",
    appId: "1:343569448122:web:50be169d300b9610a39683",
    measurementId: "G-CMR0T7Q93K"
});