<template>
    <section id="top_service_andtour" class="section_padding_top">
        <div class="container">
            <!-- Section Heading -->
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="section_heading_center">
                        <h2>And tour top services</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="top_service_boxed">
                        <img src="../../assets/img/icon/service-1.png" alt="icon">
                        <h3>Hotel Booking</h3>
                        <p>Mollit magna ex sint anim. Et velit ea in incididunt amet incididunt deserunt mollit magna
                            duis laboris eiusmod. Minim exercitation minim Lorem Id deserunt sint duis cillumaliquip.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="top_service_boxed">
                        <img src="../../assets/img/icon/service-2.png" alt="icon">
                        <h3>Flight Booking</h3>
                        <p>
                            Id aliquip qui dolore enim ea deserunt quis. Consectetur nostrud labore cupidatat proident
                            voluptate voluptate laboris ad ex eiusmod proident adipisicing enim.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="top_service_boxed">
                        <img src="../../assets/img/icon/service-3.png" alt="icon">
                        <h3>Ticket Booking</h3>
                        <p>
                            Exercitation ullamco reprehenderit amet nulla mollit do sunt. Id consequat nostrud amet
                            cillum do consectetur esse occaecat tempor reprehenderit ullamco sit aliqua liqua irure.
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="top_service_boxed">
                        <img src="../../assets/img/icon/service-4.png" alt="icon">
                        <h3>Cruises Booking</h3>
                        <p>
                            Voluptate esse culpa cupidatat irure occaecat. Laborum consectetur qui laborum nostrud et.
                            Nostrud do aliqua cillum id adipisicing anim. Id labore enim esse est
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="top_service_boxed">
                        <img src="../../assets/img/icon/service-5.png" alt="icon">
                        <h3>Rail Booking</h3>
                        <p>
                            Quis aliqua irure velit ipsum et et ipsum irure qui ut mollit sunt eiusmod do. Sunt elit
                            excepteur culpa irure elit ea culpa adipisicing. Sit officia consequat
                            excepteur
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="top_service_boxed">
                        <img src="../../assets/img/icon/service-6.png" alt="icon">
                        <h3>Amazing Tour</h3>
                        <p>
                            Labore anim culpa sint minim eu tempor cillum esse eiusmod. Sint
                            exercitation nulla qui non nostrud. Reprehenderit commodo duis ullamco anim. Incididunt
                            deserunt
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

export default {
    name: "TopService"
};
</script>