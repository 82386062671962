<template>
  <section id="theme_search_form">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="theme_search_form_area">
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="flights"
                role="tabpanel"
                aria-labelledby="flights-tab"
              >
                <div class="tab-content" id="myTabContent1">
                  <div
                    class="tab-pane fade show active"
                    id="oneway_flight"
                    role="tabpanel"
                    aria-labelledby="oneway-tab"
                  >
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="oneway_search_form">
                          <form action="#!">
                            <div class="row justify-content-center">
                              <div class="col-lg-8 col-md-8 col-sm-12 col-12">
                                <div class="form_search_date">
                                  <div
                                    class="flight_Search_boxed date_flex_area"
                                  >
                                       <label class="text-nowrap">O que você procura?</label>
                                      <input type="text" style="width: 100%" v-model="search" />
                                    
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-2 text-right">
                                <button class="btn btn_theme btn_md">
                                  Buscar
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import CountDropdown from "@/components/home/CountDropdown.vue";
import CountDropdownRound from "@/components/home/CountDropdownRound.vue";
import CountDropdownMulti from "@/components/home/CountDropdownMulti.vue";
import CountDropdownTour from "@/components/home/CountDropdownTour.vue";
import CountDropdownHotel from "@/components/home/CountDropdownHotel.vue";

export default {
  name: "Form",

  components: {
    CountDropdown,
    CountDropdownRound,
    CountDropdownMulti,
    CountDropdownTour,
    CountDropdownHotel,
  },

  data() {
    return {
      isHidden: false,
      rowData: [{ name: "" }],
      search: ''
    };
  },
  methods: {
    addRow: function () {
      let a = Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
        .substr(0, 5);

      if (document.querySelectorAll(".multi_city_form").length === 5) {
        alert("Max Citry Limit Reached!!");
        return;
      }

      this.isHidden = true;

      this.rowData.push({ name: "" });
    },
    deleteRow(index) {
      this.rowData.splice(index, 1);
    },
  },
};
</script>
