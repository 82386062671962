<template>
    <section id="dashboard_main_arae" class="section_padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="dashboard_common_table">
                        <h3>Cadastrar Hotel</h3>
                        <div class="profile_update_form">
                            <form action="!#" id="profile_form_area">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label for="f-titulo">Titulo</label>
                                            <input type="text" class="form-control" id="f-titulo" placeholder="Titulo do hotel"
                                                >
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label >Itens principais</label>
                                            <input type="text" placeholder="registe os itens separados por virgula (,)" class="form-control"  >
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <label for="f-titulo">Endereço</label>
                                            <input type="text" class="form-control" placeholder="Endereço"
                                                >
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <label for="mail-address">Descrição</label>
                                            <textarea class="form-control" rows="5"
                                                value="sherlyn@domain.com"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <label for="mail-address">Proximidades da acomodação</label>
                                            <textarea class="form-control" placeholder="registe os itens separados por virgula (,)" rows="5"
                                                value="sherlyn@domain.com"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <label for="mail-address">Regras da casa</label>
                                            <textarea class="form-control" placeholder="registe os itens separados por virgula (,)" rows="5"
                                                value="sherlyn@domain.com"></textarea>
                                        </div>
                                    </div>

                                    <div class="change_password_input_boxed">
                                        <h3>Tipo de Quartos</h3>
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="form-group">
                                                    <label for="mail-address">Nome do quarto</label>
                                                    <input type="text" class="form-control"
                                                        placeholder="Digite o nome do quarto">
                                                </div>
                                            </div>
                                            <div class="col-lg-3">
                                                <div class="form-group">
                                                    <label for="mail-address">Acomoda</label>
                                                    <input type="text" class="form-control"
                                                        placeholder="Quantidade de quartos">
                                                </div>
                                            </div>
                                            <div class="col-lg-3">
                                                <div class="form-group">
                                                    <label for="mail-address">Preço diaria</label>
                                                    <input type="text" class="form-control"
                                                        placeholder="Preço do quarto">
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="form-group">
                                                    <textarea class="form-control"
                                                        placeholder="Descrição" rows="3"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="change_password_input_boxed">
                                        <h3>Adicionar fotos</h3>
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="form-group">
                                                  <input class="form-control" id="formFileLg" type="file">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import LogoutBtn from '@/components/dashboard/LogoutBtn.vue'
import AdminSidebar from '@/components/dashboard/AdminSidebar.vue'

export default {
    name: "ProfileAdmin",
    components: {
        LogoutBtn, AdminSidebar
    }
};
</script>