<template>
    <!-- Dashboard Area -->
    <ProfileAdmin />
</template>
<script>

import MyProfileBanner from '@/components/dashboard/MyProfileBanner.vue'
import ProfileAdmin from '@/components/dashboard/ProfileAdmin.vue'
import Cta from '@/components/home/Cta.vue'

export default {
    name: "ProfileAdminView",
    components: {
        MyProfileBanner, ProfileAdmin, Cta
    }
};
</script>
