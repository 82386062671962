<template>
  <div>
    <header class="main_header_arae">
      <!-- Navbar Bar -->
      <div class="navbar-area" :class="{ 'is-sticky': isSticky || $route.name === 'hotel-details' }">
        <!-- menu mobile -->
        <div class="main-responsive-nav py-0">
          <div class="container">
            <div class="main-responsive-menu mean-container">
              <nav class="navbar">
                <div class="container">
                  <div class="logo">
                    <router-link class="" to="/">
                      <img src="/viagemok.png" style="width: 170px" alt="logo" />
                    </router-link>
                  </div>
                  <button
                    class="navbar-toggler collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbar-content"
                  >
                    <div class="hamburger-toggle">
                      <div class="hamburger">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </button>
                  <div
                    class="collapse navbar-collapse mean-nav"
                    id="navbar-content"
                  >
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>

        <!-- Desktop menu -->
        <div class="main-navbar">
          <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
              <router-link class="" to="/">
                <img src="/viagemok.png" style="width: 220px" alt="logo" />
              </router-link>

              <div
                class="collapse navbar-collapse mean-menu"
                id="navbarSupportedContent"
              >
              <ul></ul>
                <div class="others-options d-flex align-items-center">
                  <div class="option-item">
                    <router-link v-if="!$route.fullPath.includes('admin')" to="/become-vendor" class="btn btn_navber"
                      >Torne-se um associado</router-link
                    >
                    <LogoutBtn v-else />
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <div class="others-option-for-responsive">
          <div class="container">
            <div class="container">
              <div class="option-inner">
                <div class="others-options d-flex align-items-center">
                  <div class="option-item">
                    <a href="#" class="search-box"
                      ><i class="fas fa-search"></i
                    ></a>
                  </div>
                  <div class="option-item">
                    <router-link v-if="!$route.fullPath.includes('admin')" to="/become-vendor" class="btn btn_navber"
                      >Torne-se um associado</router-link
                    >
                    <LogoutBtn v-else />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>
<script>
import LogoutBtn from '@/components/dashboard/LogoutBtn.vue'

export default {
  name: "Header",
  components: {
    LogoutBtn
  },
  data() {
    return {
      language: "English",
      currency: "USD",
      isSticky: false,
    };
  },

  mounted() {
    window.addEventListener("scroll", () => {
      let scroll = window.scrollY;
      if (scroll >= 200) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    });

    document.addEventListener("click", function (e) {
      // Hamburger menu
      if (e.target.classList.contains("hamburger-toggle")) {
        e.target.children[0].classList.toggle("active");
      }
    });
  },
};
</script>

