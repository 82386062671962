<template>
    <section id="explore_area" class="section_padding_top">
        <div class="container">
            <!-- Section Heading -->
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="section_heading_center">
                        <h2>Explore nossas ofertas quentes</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-hotels" role="tabpanel"
                            aria-labelledby="nav-hotels-tab">
                            <div class="row">
                                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                    <div class="theme_common_box_two img_hover">
                                        <div class="theme_two_box_img">
                                            <router-link to="/hotel-details">
                                                <img src="../../assets/img/hotel/hotel1.png" alt="img">
                                            </router-link>
                                            <p><i class="fas fa-map-marker-alt"></i>Rio de Janeiro</p>
                                        </div>
                                        <div class="theme_two_box_content">
                                            <h4><router-link to="/hotel-details">Kantua hotel, Thailand</router-link></h4>
                                            <p><span class="review_rating"><i class="fas fa-star text-warning"></i> 4.5/5 Excelente</span> </p>
                                            <h3>R$190,00 <span>diária</span></h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                    <div class="theme_common_box_two img_hover">
                                        <div class="theme_two_box_img">
                                            <router-link to="/hotel-details">
                                                <img src="../../assets/img/hotel/hotel2.png" alt="img">
                                            </router-link>
                                            <p><i class="fas fa-map-marker-alt"></i>São Paulo</p>
                                            <div class="discount_tab">
                                                <span>50%</span>
                                            </div>

                                        </div>
                                        <div class="theme_two_box_content">
                                            <h4><router-link to="/hotel-details">Hotel paradise international</router-link></h4>
                                            <p><span class="review_rating"><i class="fas fa-star text-warning"></i> 4.5/5 Excelente</span> </p>
                                            <h3>R$130,00 <span>diária</span></h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                    <div class="theme_common_box_two img_hover">
                                        <div class="theme_two_box_img">
                                            <router-link to="/hotel-details">
                                                <img src="../../assets/img/hotel/hotel3.png" alt="img">
                                            </router-link>
                                            <p><i class="fas fa-map-marker-alt"></i>São Paulo</p>
                                        </div>
                                        <div class="theme_two_box_content">
                                            <h4><router-link to="/hotel-details">Hotel kualalampur</router-link></h4>
                                            <p><span class="review_rating"><i class="fas fa-star text-warning"></i> 4.5/5 Excelente</span> </p>
                                            <h3>R$178,00 <span>diária</span></h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                    <div class="theme_common_box_two img_hover">
                                        <div class="theme_two_box_img">
                                            <router-link to="/hotel-details">
                                                <img src="../../assets/img/hotel/hotel2.png" alt="img">
                                            </router-link>
                                            <p><i class="fas fa-map-marker-alt"></i>Curitiba</p>
                                        </div>
                                        <div class="theme_two_box_content">
                                            <h4><router-link to="/hotel-details">Hotel deluxe</router-link></h4>
                                            <p><span class="review_rating"><i class="fas fa-star text-warning"></i> 4.5/5 Excelente</span> </p>
                                            <h3>R$200,00 <span>diária</span></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="nav-tours" role="tabpanel" aria-labelledby="nav-tours-tab">
                            <div class="row">
                                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                    <div class="theme_common_box_two img_hover">
                                        <div class="theme_two_box_img">
                                            <router-link to="/hotel-details">
                                                <img src="../../assets/img/tab-img/hotel1.png" alt="img">
                                            </router-link>
                                            <p><i class="fas fa-map-marker-alt"></i>New beach, Thailand</p>
                                        </div>
                                        <div class="theme_two_box_content">
                                            <h4><router-link to="/hotel-details">Kantua hotel, Thailand</router-link></h4>
                                            <p><span class="review_rating"><i class="fas fa-star text-warning"></i> 4.5/5 Excelente</span> </p>
                                            <h3>R$100,00 <span>diária</span></h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                    <div class="theme_common_box_two img_hover">
                                        <div class="theme_two_box_img">
                                            <router-link to="/hotel-details">
                                                <img src="../../assets/img/tab-img/hotel3.png" alt="img">
                                            </router-link>
                                            <p><i class="fas fa-map-marker-alt"></i>Kualalampur</p>
                                        </div>
                                        <div class="theme_two_box_content">
                                            <h4><router-link to="/hotel-details">Hotel kualalampur</router-link></h4>
                                            <p><span class="review_rating"><i class="fas fa-star text-warning"></i> 4.5/5 Excelente</span> </p>
                                            <h3>R$170,00 <span>diária</span></h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                    <div class="theme_common_box_two img_hover">
                                        <div class="theme_two_box_img">
                                            <router-link to="/hotel-details">
                                                <img src="../../assets/img/tab-img/hotel8.png" alt="img">
                                            </router-link>
                                            <p><i class="fas fa-map-marker-alt"></i>Philippine</p>
                                        </div>
                                        <div class="theme_two_box_content">
                                            <h4><router-link to="/hotel-details">Manila international resort</router-link></h4>
                                            <p><span class="review_rating"><i class="fas fa-star text-warning"></i> 4.5/5 Excelente</span> </p>
                                            <h3>R$199,00 <span>diária</span></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade show active" id="nav-hotels" role="tabpanel"
                            aria-labelledby="nav-hotels-tab">
                            <div class="row">
                                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                    <div class="theme_common_box_two img_hover">
                                        <div class="theme_two_box_img">
                                            <router-link to="/hotel-details">
                                                <img src="../../assets/img/hotel/hotel1.png" alt="img">
                                            </router-link>
                                            <p><i class="fas fa-map-marker-alt"></i>Rio de Janeiro</p>
                                        </div>
                                        <div class="theme_two_box_content">
                                            <h4><router-link to="/hotel-details">Kantua hotel, Thailand</router-link></h4>
                                            <p><span class="review_rating"><i class="fas fa-star text-warning"></i> <i class="fas fa-star text-warning"></i> 4.5/5 Excelente</span> </p>
                                            <h3>R$190,00 <span>diária</span></h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                    <div class="theme_common_box_two img_hover">
                                        <div class="theme_two_box_img">
                                            <router-link to="/hotel-details">
                                                <img src="../../assets/img/hotel/hotel2.png" alt="img">
                                            </router-link>
                                            <p><i class="fas fa-map-marker-alt"></i>São Paulo</p>
                                            <div class="discount_tab">
                                                <span>50%</span>
                                            </div>

                                        </div>
                                        <div class="theme_two_box_content">
                                            <h4><router-link to="/hotel-details">Hotel paradise international</router-link></h4>
                                            <p><span class="review_rating"><i class="fas fa-star text-warning"></i> 4.5/5 Excelente</span> </p>
                                            <h3>R$130,00 <span>diária</span></h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                    <div class="theme_common_box_two img_hover">
                                        <div class="theme_two_box_img">
                                            <router-link to="/hotel-details">
                                                <img src="../../assets/img/hotel/hotel3.png" alt="img">
                                            </router-link>
                                            <p><i class="fas fa-map-marker-alt"></i>São Paulo</p>
                                        </div>
                                        <div class="theme_two_box_content">
                                            <h4><router-link to="/hotel-details">Hotel kualalampur</router-link></h4>
                                            <p><span class="review_rating"><i class="fas fa-star text-warning"></i> 4.5/5 Excelente</span> </p>
                                            <h3>R$178,00 <span>diária</span></h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                    <div class="theme_common_box_two img_hover">
                                        <div class="theme_two_box_img">
                                            <router-link to="/hotel-details">
                                                <img src="../../assets/img/hotel/hotel2.png" alt="img">
                                            </router-link>
                                            <p><i class="fas fa-map-marker-alt"></i>Curitiba</p>
                                        </div>
                                        <div class="theme_two_box_content">
                                            <h4><router-link to="/hotel-details">Hotel deluxe</router-link></h4>
                                            <p><span class="review_rating"><i class="fas fa-star text-warning"></i> 4.5/5 Excelente</span> </p>
                                            <h3>R$200,00 <span>diária</span></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="nav-tours" role="tabpanel" aria-labelledby="nav-tours-tab">
                            <div class="row">
                                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                    <div class="theme_common_box_two img_hover">
                                        <div class="theme_two_box_img">
                                            <router-link to="/hotel-details">
                                                <img src="../../assets/img/tab-img/hotel1.png" alt="img">
                                            </router-link>
                                            <p><i class="fas fa-map-marker-alt"></i>New beach, Thailand</p>
                                        </div>
                                        <div class="theme_two_box_content">
                                            <h4><router-link to="/hotel-details">Kantua hotel, Thailand</router-link></h4>
                                            <p><span class="review_rating"><i class="fas fa-star text-warning"></i> 4.5/5 Excelente</span> </p>
                                            <h3>R$100,00 <span>diária</span></h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                    <div class="theme_common_box_two img_hover">
                                        <div class="theme_two_box_img">
                                            <router-link to="/hotel-details">
                                                <img src="../../assets/img/tab-img/hotel3.png" alt="img">
                                            </router-link>
                                            <p><i class="fas fa-map-marker-alt"></i>Kualalampur</p>
                                        </div>
                                        <div class="theme_two_box_content">
                                            <h4><router-link to="/hotel-details">Hotel kualalampur</router-link></h4>
                                            <p><span class="review_rating"><i class="fas fa-star text-warning"></i> 4.5/5 Excelente</span> </p>
                                            <h3>R$170,00 <span>diária</span></h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                    <div class="theme_common_box_two img_hover">
                                        <div class="theme_two_box_img">
                                            <router-link to="/hotel-details">
                                                <img src="../../assets/img/tab-img/hotel8.png" alt="img">
                                            </router-link>
                                            <p><i class="fas fa-map-marker-alt"></i>Philippine</p>
                                        </div>
                                        <div class="theme_two_box_content">
                                            <h4><router-link to="/hotel-details">Manila international resort</router-link></h4>
                                            <p><span class="review_rating"><i class="fas fa-star text-warning"></i> 4.5/5 Excelente</span> </p>
                                            <h3>R$199,00 <span>diária</span></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade show active" id="nav-hotels" role="tabpanel"
                            aria-labelledby="nav-hotels-tab">
                            <div class="row">
                                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                    <div class="theme_common_box_two img_hover">
                                        <div class="theme_two_box_img">
                                            <router-link to="/hotel-details">
                                                <img src="../../assets/img/hotel/hotel1.png" alt="img">
                                            </router-link>
                                            <p><i class="fas fa-map-marker-alt"></i>Rio de Janeiro</p>
                                        </div>
                                        <div class="theme_two_box_content">
                                            <h4><router-link to="/hotel-details">Kantua hotel, Thailand</router-link></h4>
                                            <p><span class="review_rating"><i class="fas fa-star text-warning"></i> 4.5/5 Excelente</span> </p>
                                            <h3>R$190,00 <span>diária</span></h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                    <div class="theme_common_box_two img_hover">
                                        <div class="theme_two_box_img">
                                            <router-link to="/hotel-details">
                                                <img src="../../assets/img/hotel/hotel2.png" alt="img">
                                            </router-link>
                                            <p><i class="fas fa-map-marker-alt"></i>São Paulo</p>
                                            <div class="discount_tab">
                                                <span>50%</span>
                                            </div>

                                        </div>
                                        <div class="theme_two_box_content">
                                            <h4><router-link to="/hotel-details">Hotel paradise international</router-link></h4>
                                            <p><span class="review_rating"><i class="fas fa-star text-warning"></i> 4.5/5 Excelente</span> </p>
                                            <h3>R$130,00 <span>diária</span></h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                    <div class="theme_common_box_two img_hover">
                                        <div class="theme_two_box_img">
                                            <router-link to="/hotel-details">
                                                <img src="../../assets/img/hotel/hotel3.png" alt="img">
                                            </router-link>
                                            <p><i class="fas fa-map-marker-alt"></i>São Paulo</p>
                                        </div>
                                        <div class="theme_two_box_content">
                                            <h4><router-link to="/hotel-details">Hotel kualalampur</router-link></h4>
                                            <p><span class="review_rating"><i class="fas fa-star text-warning"></i> 4.5/5 Excelente</span> </p>
                                            <h3>R$178,00 <span>diária</span></h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                    <div class="theme_common_box_two img_hover">
                                        <div class="theme_two_box_img">
                                            <router-link to="/hotel-details">
                                                <img src="../../assets/img/hotel/hotel2.png" alt="img">
                                            </router-link>
                                            <p><i class="fas fa-map-marker-alt"></i>Curitiba</p>
                                        </div>
                                        <div class="theme_two_box_content">
                                            <h4><router-link to="/hotel-details">Hotel deluxe</router-link></h4>
                                            <p><span class="review_rating"><i class="fas fa-star text-warning"></i> 4.5/5 Excelente</span> </p>
                                            <h3>R$200,00 <span>diária</span></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="nav-tours" role="tabpanel" aria-labelledby="nav-tours-tab">
                            <div class="row">
                                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                    <div class="theme_common_box_two img_hover">
                                        <div class="theme_two_box_img">
                                            <router-link to="/hotel-details">
                                                <img src="../../assets/img/tab-img/hotel1.png" alt="img">
                                            </router-link>
                                            <p><i class="fas fa-map-marker-alt"></i>New beach, Thailand</p>
                                        </div>
                                        <div class="theme_two_box_content">
                                            <h4><router-link to="/hotel-details">Kantua hotel, Thailand</router-link></h4>
                                            <p><span class="review_rating"><i class="fas fa-star text-warning"></i> 4.5/5 Excelente</span> </p>
                                            <h3>R$100,00 <span>diária</span></h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                    <div class="theme_common_box_two img_hover">
                                        <div class="theme_two_box_img">
                                            <router-link to="/hotel-details">
                                                <img src="../../assets/img/tab-img/hotel3.png" alt="img">
                                            </router-link>
                                            <p><i class="fas fa-map-marker-alt"></i>Kualalampur</p>
                                        </div>
                                        <div class="theme_two_box_content">
                                            <h4><router-link to="/hotel-details">Hotel kualalampur</router-link></h4>
                                            <p><span class="review_rating"><i class="fas fa-star text-warning"></i> 4.5/5 Excelente</span> </p>
                                            <h3>R$170,00 <span>diária</span></h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                                    <div class="theme_common_box_two img_hover">
                                        <div class="theme_two_box_img">
                                            <router-link to="/hotel-details">
                                                <img src="../../assets/img/tab-img/hotel8.png" alt="img">
                                            </router-link>
                                            <p><i class="fas fa-map-marker-alt"></i>Philippine</p>
                                        </div>
                                        <div class="theme_two_box_content">
                                            <h4><router-link to="/hotel-details">Manila international resort</router-link></h4>
                                            <p><span class="review_rating"><i class="fas fa-star text-warning"></i> 4.5/5 Excelente</span> </p>
                                            <h3>R$199,00 <span>diária</span></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

export default {
    name: "ExploreDeals"
};
</script>