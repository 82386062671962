import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import { VueFire, VueFireAuth } from 'vuefire'
import router from './router'
import { firebaseApp } from '@/root/firebase';

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

// Main CSS Add
import './assets/app.css'

const app = createApp(App);
const pinia = createPinia();

app.use(VueFire, {
    // imported above but could also just be created here
    firebaseApp,
    modules: [
      // we will see other modules later on
      VueFireAuth(),
    ],
  })
app.use(router);
app.use(pinia);
app.mount('#app');