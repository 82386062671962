<template>
    <!-- About Service Area -->
    <AboutService style="padding-top: 8rem" />

    <!-- Vendor Form Area -->
    <VendorForm />

</template>
<script>
import Banner from '@/components/about/Banner.vue'
import HowWork from '@/components/vendor/HowWork.vue'
import Video from '@/components/vendor/Video.vue'
import AboutService from '@/components/vendor/AboutService.vue'
import VendorForm from '@/components/vendor/VendorForm.vue'
import Cta from '@/components/home/Cta.vue'

export default {
    name: "BecomeVendorView",
    components: {
        Banner, HowWork, Video, AboutService, VendorForm, Cta
    }
};
</script>